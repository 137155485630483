class Transition {
  static TO_LOADING = "to_loading"
  static TO_LOADED = "to_loaded"
}

export class Blog {

    isTransitioning = false;

    constructor()
    {
        this.blog = document.querySelector('*[block="blog"]');
        if (!this.blog) { return }
        this.init()
    }

    init()
    {
        this.container = this.blog.querySelector("*[ajax-container]")
        this.trigger = this.blog.querySelector("*[ajax-trigger]")
        this.skeleton = this.blog.querySelector("*[ajax-skeleton]")
        this.trigger.addEventListener('click', this.load.bind(this))
    }

    async load(e)
    {

        e.preventDefault();
        if(this.isTransitioning){ return }

        this.transition(Transition.TO_LOADING)

        const currentOffset = this.trigger.hasAttribute("ajax-offset") ? this.trigger.getAttribute("ajax-offset") : 8;
        const response = await app.call("posts", { params: { offset: currentOffset } })
        const data = response.data.original
        data.forEach((post) => { this.container.innerHTML += this.template(post) })
        this.handleEdgeCases(data)
        this.updateOffset(currentOffset)

        this.transition(Transition.TO_LOADED)

    }

    transition(transition)
    {

      if(transition === Transition.TO_LOADING)
      {
        this.trigger.classList.add("hidden")
        this.skeleton.classList.remove("hidden")
        this.isTransitioning = true;
      }

      else if(transition === Transition.TO_LOADED)
      {
        this.trigger.classList.remove("hidden")
        this.skeleton.classList.add("hidden")
        this.isTransitioning = false;
      }

    }

    template(context)
    {
        return `
            <div class="card">
              <div class="image w-100">
                <a href="${context["link"]}" class="w-100 h-100">
                  ${context["thumbnail"]}
                </a>
              </div>
              <div class="content">
                <p class="post__title">${context["title"]}</p>
                <p class="post__excerpt">${context["excerpt"]}</p>
                <a class="post__link" href="${context["link"]}">Lire la suite</a>
              </div>
            </div>
        `
    }

    handleEdgeCases(data)
    {
      if(data.length < 8) { this.trigger.remove(); }
    }

    updateOffset(currentOffset)
    {
        const newOffset = parseInt(currentOffset) + 8;
        this.trigger.setAttribute("ajax-offset", newOffset);
    }

}

