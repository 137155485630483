import Swiper, { Pagination, Autoplay } from 'swiper';

export class WineSlider {

    constructor()
    {
        this.slider = document.querySelector("*[slider='wine']");
        if (!this.slider) { return }
        this.init();
    }

    init()
    {
        const swiper = new Swiper(this.slider, {
            modules: [ Pagination, Autoplay ],
            pagination: {
                el: '.swiper-pagination',
              clickable: true
            },
            slidesPerView: 3,
            spaceBetween: 25,
            slidesPerGroup: 3,
            loopFillGroupWithBlank: true,
            loop: true,
            autoplay: {
              delay: 3000,
              disableOnInteraction: false,
            },
            breakpoints: {
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 40,
                    slidesPerGroup: 3,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                    slidesPerGroup: 2,
                },
                640: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                    slidesPerGroup: 1,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                    slidesPerGroup: 1,
                }
            }
        });
    }

}
