// import { HRMHandler } from "@js/utils/hmr";

export class Loader {
    load(scripts) {
        // scripts = [HRMHandler, ...scripts];
        scripts.forEach((script) => { new script(); })
        this.loadComponents();
    }
    loadComponents() { import("../components/components.js") }

}
