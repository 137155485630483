export class Header {

    constructor()
    {
        this.header = document.querySelector("header.banner");
        if (!this.header) { return; }
        this.init();
    }

    init()
    {
        this.handleHomePageEdgeCase();
        this.handleScroll();
        this.handleMobileMenu();
    }

    handleHomePageEdgeCase()
    {
        this.isHome = document.body.classList.contains("home")
        if (!this.isHome) { return }
        this.header.classList.add("--home");
    }

    handleScroll()
    {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 0) { this.header.classList.add("--scrolled"); }
            else { this.header.classList.remove("--scrolled"); }
        });
    }

    handleMobileMenu()
    {
      const mobile = this.header.querySelector(".menu__mobile");
      const open = mobile.querySelector(".mobile__open");

      // open menu on click
      open.addEventListener("click", () => {
        mobile.removeAttribute("close");
        mobile.setAttribute("open", "");
      });

      // close menu on click outside button
      document.addEventListener("click", (e) => {
        const state = mobile.hasAttribute("open") ? "open" : "close";
        if (state === "close") { return; }
        if (e.target.closest(".menu__mobile")) { return; }
        mobile.removeAttribute("open");
        mobile.setAttribute("close", "");
      })
    }

}
