import axios from 'axios';



class app {
    static baseURL = window.location.origin
    static endpoint = `${app.baseURL}/wp-json/akyos/v1`;
    static async call(endpoint, options = {})
    {
        return await axios.get(app.endpoint + "/" + endpoint, options);
    }
}

window.app = app;
console.log(app.endpoint)

