import TomSelect from "tom-select";

export class Select
{
    constructor()
    {
        this.selects = document.querySelectorAll("select")
        if (this.selects.length === 0) { return }
        this.init()
    }

    init()
    {
        this.selects.forEach(async(select) => {
            const placeholder = select.getAttribute("data-placeholder") ?? "Choisir une option";
            await select.setAttribute("placeholder", placeholder)
            const tomSelect = new TomSelect(select, {
              // controlInput: null,
            });
            tomSelect.settings.placeholder = placeholder
            tomSelect.inputState();
        })
    }

}
