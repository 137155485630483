import Swiper, { Pagination } from 'swiper';

export class TextImageDesign2Slider {

    constructor()
    {
        this.sliders = document.querySelectorAll("*[slider='text_image_design_2']");
        if (this.sliders.length === 0) { return; }
        this.init();
    }

    init()
    {
      this.sliders.forEach((slider) => {
        const swiper = new Swiper(slider, {
          modules: [ Pagination ],
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          },
          slidesPerView: 1,
          loop: true,
        });
      })
    }

}
