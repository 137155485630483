// =========================================================
import '@css/main.scss';
import '@js/bootstrap';
import { Loader } from '@js/utils/loader';
// =========================================================
import { WineSlider } from "@js/scripts/WineSlider";
import { Header } from "@js/scripts/Header";
import { AnimatedCounters } from "@js/scripts/AnimatedCounters";
import { Blog } from "@js/scripts/Blog";
import { CopyOnClick } from "@js/scripts/CopyOnClick";
import { Select } from "@js/scripts/Select";
import {WineFilter} from "@js/scripts/WineFilter";
import {TextImageDesign2Slider} from "@js/scripts/TextImageDesign2Slider";

const scripts = [
  WineSlider, Header, AnimatedCounters, Blog, CopyOnClick, Select, WineFilter, TextImageDesign2Slider
];

window.onload = () => { new Loader().load(scripts) }
