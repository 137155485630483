export class WineFilter {

    static ALL = "__empty"
    static TRANSITION_TO_HIDDEN = "to_hidden"
    static TRANSITION_TO_VISIBLE = "to_visible"

    currentFilter = WineFilter.ALL
    itemsSelector = ".wine-card"
    isTransitionning = false

    constructor()
    {
        this.wine = document.querySelector('*[archive="wine"]');
        if (!this.wine) { return }
        this.init()
    }

    init()
    {
        this.filterButtons = this.wine.querySelectorAll(".filters__item")
        this.filterButtons.forEach((button) => {
          button.addEventListener("click", async () => {
            if(this.isTransitionning){ return }
            const filter = button.getAttribute("filter")
            if (filter === this.currentFilter) { return }
            this.currentFilter = filter
            this.updateButtons()
            await this.filter(filter)
          })
        })
    }

    async filter(filter)
    {
      this.isTransitionning = true
      await this.hide(WineFilter.ALL)
      await this.show(filter)
      this.isTransitionning = false
    }

    async hide(filter)
    {
      const items = filter === WineFilter.ALL ?
        this.wine.querySelectorAll(this.itemsSelector) :
        this.wine.querySelectorAll(`${this.itemsSelector}[wine_type="${filter}"]`)
      await this.transition(items, WineFilter.TRANSITION_TO_HIDDEN)
    }

    async show(filter)
    {
      const items = filter === WineFilter.ALL ?
        this.wine.querySelectorAll(this.itemsSelector) :
        this.wine.querySelectorAll(`${this.itemsSelector}[wine_type="${filter}"]`)
      await this.transition(items, WineFilter.TRANSITION_TO_VISIBLE)
    }

    async transition(els, state)
    {
      els.forEach(async (el) => {
        el.style.transition = "opacity .3s ease-in-out"
        if(state === WineFilter.TRANSITION_TO_VISIBLE){ el.style.display = "block" }
        await sleep(100)
        el.style.opacity = state === WineFilter.TRANSITION_TO_HIDDEN ? 0 : 1
      })
      await sleep(300)
      if(state === WineFilter.TRANSITION_TO_HIDDEN){ els.forEach((el) => { el.style.display = "none" }) }
    }

    updateButtons()
    {
      this.filterButtons.forEach((button) => {
        const filter = button.getAttribute("filter")
        if(filter === this.currentFilter) { button.setAttribute("filter-active", "") }
        if(button.hasAttribute("filter-active") && filter !== this.currentFilter) { button.removeAttribute("filter-active") }
      })
    }

}

async function sleep(ms) { return new Promise((resolve) => { setTimeout(resolve, ms); }); }

