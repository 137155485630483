import { CountUp } from 'countup.js';

export class AnimatedCounters {

    constructor()
    {
        this.options = {
            root: null,
            threshold: 0.25,
            rootMargin: '-100px',
        };
        this.elements = document.querySelectorAll('[animated_count]')
        this.toggled = {}
        this.elements.forEach((element) => {
            let temp_id = this.generateTempID()
            element.setAttribute('temp_id', temp_id)
            this.toggled[temp_id] = false
        })
        this.firstTriggers = this.elements.length
        this.registerObservers()
    }

    generateTempID()
    {
        let fourChars = () => {
            return (((1 + Math.random()) * 0x10000)|0).toString(16).substring(1).toUpperCase();
        }
        return (fourChars() + fourChars() + '-' + fourChars() + '-' + fourChars() + '-' + fourChars() + '-' + fourChars() + fourChars() + fourChars());
    }

    getTempID(element)
    {
        return element.getAttribute('temp_id')
    }

    removeTempID(element)
    {
        element.removeAttribute('temp_id')
    }

    registerObservers()
    {
        let observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (this.firstTriggers !== 0) {
                    this.firstTriggers -= 1
                    return;
                }
                if (!this.toggled[this.getTempID(entry.target)]) {
                    this.animate(entry.target)
                    this.toggled[this.getTempID(entry.target)] = true
                }
            })
        }, self.options)

        this.elements.forEach((element) => {
            observer.observe(element);
        })
    }

    animate(element)
    {
        this.removeTempID(element)
        let countUpNumber = new CountUp(element, parseInt(element.innerText));
        if (!countUpNumber.error) {
            countUpNumber.start();
        }
    }

}

