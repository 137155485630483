import Toastify from 'toastify-js'

export class CopyOnClick {

    constructor()
    {
        this.elements = document.querySelectorAll('*[copy-on-click]')
        if (!this.elements.length) {
            return }
        this.init()
    }

    init()
    {
        this.elements.forEach((element) => {
            element.addEventListener('click', this.copy.bind(this))
        })
    }

    copy(e)
    {
        const el = e.target.closest('*[copy-on-click]')
        console.log(el.getAttribute('copy-on-click-message'))
        console.log(el)
        const text = el.getAttribute('copy-on-click')
        const message = el.hasAttribute('copy-on-click-message') ? el.getAttribute('copy-on-click-message') : 'Copied to clipboard'
        const input = document.createElement('input')
        input.setAttribute('value', text)
        document.body.appendChild(input)
        input.select()
        document.execCommand('copy')
        document.body.removeChild(input)
        Toastify({
            text: message,
            duration: 3000,
            className: "info",
            style: {
                background: "#55f27c",
            }
        }).showToast();
    }

}

